<script>
export default {
    name: "VPasswordScore",
    props: {
        score: {},
        feedback: {},
    },
    data() {
        return {
            scoreWord: {
                1: this.$t("password.weak"),
                2: this.$t("password.fair"),
                3: this.$t("password.good"),
                4: this.$t("password.strong"),
            },
        };
    },
};
</script>

<template>
    <div class="password-score">
        <div class="password-score__row">
            <div
                class="password-score__row__score1"
                :class="{ active: score >= 1 }"
            ></div>
            <div
                class="password-score__row__score2"
                :class="{ active: score >= 2 }"
            ></div>            
            <div
                class="password-score__row__score4"
                :class="{ active: score == 4 }"
            ></div>
        </div>
        <div class="password-score__word">
            {{ $t("password.score") }}:
            <template v-if="score">{{ scoreWord[score] }}</template>
            <template v-else>---</template>
        </div>
        <div class="password-score__feedback" v-if="feedback">
            <transition name="fade">
                <div
                    v-if="feedback.warning"
                    class="mt-4 alert alert-danger alert-outline"
                >
                    <i class="far fa-siren-on"></i> {{ feedback.warning }}
                </div>

                <template v-if="'suggestions' in feedback && feedback.suggestions.length > 0">
                    <ul>
                        <li
                            v-for="(suggestion, index) in feedback.suggestions"
                            :key="index"
                        >
                            {{ suggestion }}
                        </li>
                    </ul>
                </template>
            </transition>
        </div>
    </div>
</template>



<style lang="scss">
.password-score {
    &__word {
        margin-top: 15px;
        text-align: right;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
            background-color: $gray-200;
            border: 1px solid white;
            border-radius: 4px;
            width: 32%;
            height: 10px;
        }

        &__score1 {
            &.active {
                background-color: #aa0033;
            }
        }

        &__score2 {
            &.active {
                background-color: #ffcc33;
            }
        }

        &__score3 {
            &.active {
                background-color: #2d98f3;
            }
        }

        &__score4 {
            &.active {
                background-color: #76c261;
            }
        }
    }
}
</style>